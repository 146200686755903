<template>
  <div
      class="pointer border border-gray-300 rounded-lg overflow-hidden ss-cross-sell-step relative transition duration-150 md:max-w-[275px] w-full sm:w-[calc(51.1%-16px)]"
      :style="computedStyle"
      @click="sendData"
  >
    <TagSelected v-if="value > 0"/>
    <div v-if="accessory.code === 'none'"
         class="border-b border-gray-300  overflow-hidden ss-cross-sell-step relative transition duration-150 w-full "
    >
      <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
           class="mx-auto w-[70%] text-gray-300 p-4">
        <path
            d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/>
      </svg>
    </div>
    <ImageFromSet v-else :images="accessory.images" class="mx-auto mb-4 w-full border-b border-gray-300"/>
    <div class="text-center" v-if="accessory.code !== 'none'">
      <AppButton @click.prevent="$emit('input', value - 1)" class="h-10 w-10 align-top justify-center">-</AppButton>
      <input :id="accessory.code" type="text" :value="value" @input="$emit('input', $event.target.value)"
             class="h-10 text-center ml-2 mr-2 md:ml-0.5 md:mr-0.5 lg:ml-2 lg:mr-2 border-2 p-2 ss-input max-w-[50px] bg-transparent">
      <AppButton @click.prevent="$emit('input', value + 1)" class="h-10 w-10 align-top justify-center">+</AppButton>
    </div>

    <div class="p-4">
      <label :for="accessory.code" class="space-y-2">
        <template v-if="showPrice">
                    <span class="inline-block text-sm font-light" :style="`color:${style.color}`">
                        {{ formatCurrency(this.price, getPriceFormat) }} {{ $t('a piece') }}
                    </span><br>
        </template>
        <span class="inline-block">{{ accessory.translations[$i18n.locale].name }}</span><br>
        <span v-if="description" class="inline-block text-sm text-gray-600">{{ description }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import {formatCurrencyMixin} from '../mixins';
import AppButton from './AppButton';
import ImageFromSet from './ImageFromSet';
import TagSelected from './TagSelected';

export default {
  components: {
    AppButton,
    ImageFromSet,
    TagSelected,
  },
  mixins: [formatCurrencyMixin],
  props: {
    accessory: {type: Object, required: true},
    price: {type: Number, required: true},
    value: {type: Number, required: true},
    showPrice: Boolean,
  },
  computed: {
    getPriceFormat() {
      return this.$store.getters.getPriceFormat;
    },
    computedStyle() {
      let style = {};
      if (this.value > 0) {
        style.borderColor = this.borderStyle.background;
      }
      if (this.accessory.code === 'none') {
        style.cursor = 'pointer';
      }
      return style;
    },
    style() {
      return this.$store.getters.styleFor('container');
    },
    borderStyle() {
      return this.$store.getters.styleFor('buttons');
    },
    description() {
      return this.accessory.translations[this.$i18n.locale]?.description ?? null;
    },
  },
  methods: {
    sendData() {
      if (this.accessory.code !== 'none') {
        return;
      }
      this.$emit('none')
    }
  }
};
</script>
