<template>
    <div>
        <AppSpinner v-if="loading">{{ $t('Calculating') }}...</AppSpinner>
        <template v-else-if="errorMessage">
            {{ $t(`This product cannot be ordered right now. Please contact us.`) }}
        </template>
        <template v-else>
            <div class="sm:flex flex-wrap justify-between items-end gap-x-6">
                <div class="text-sm grid grid-cols-2x-auto gap-x-4">
                    <template v-for="step of visibleSteps">
                        <span :key="'title_' + step._persistant_index" class="text-gray-600">
                            <SmartLabel :labels="step.title"/>
                        </span>
                        <span :key="'input.summary_' + step._persistant_index">{{ getStepInputSummary(step) }}</span>
                    </template>
                </div>
                <div class="flex-shrink-0 sm:text-right space-y-2 mt-4 sm:mt-0">
                    <div>
                        <template v-if="showPriceExclVat">
                            {{ formatCurrency(totalPriceExclVat, getPriceFormat) }} <span
                            class="text-gray-600">{{ $t('excl. VAT') }}</span><br>
                        </template>
                        <template v-if="showPriceInclVat">
                            {{ formatCurrency(totalPriceInclVat, getPriceFormat) }} <span
                            class="text-gray-600">{{ $t('incl. VAT') }}</span><br>
                        </template>
                    </div>

                    <AppButton v-if="requestFilesBeforeAddToCart" icon="next" @click="$emit('show-uploader')">
                        {{ $t('Upload design file(s)') }}
                    </AppButton>
                    <AppButton v-else icon="cart" @click="$emit('confirm')">
                        {{ $t('Add to cart') }}
                    </AppButton>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { VAT_PERCENTAGE, VENDOR } from '../constants';
import { HTTP } from '../http-common';
import { formatCurrencyMixin } from '../mixins';
import { transformInputToProboModel } from '../transformInputToProboModel';
import { isTrueOrStringTrue } from '../utilities';
import AppButton from './AppButton';
import AppSpinner from './AppSpinner';
import SmartLabel from './SmartLabel';

export default {
    components: {
        AppButton,
        AppSpinner,
        SmartLabel,
    },
    mixins: [formatCurrencyMixin],
    props: {
        precalculatedTotalPriceExclVat: { type: Number, default: null },
    },
    data() {
        return {
            errorMessage: null,
        };
    },
    computed: {
        ...mapState({
            uuid: state => state.configuration.uuid,
            vendorId: state => state.configuration.product.vendor_id,
            pricingSettings: state => state.configuration.settings.pricing,
            uploadRequired: state => state.configuration.settings.upload_strategy === 'required',
            userWantsToUploadFiles: state => state.userWantsToUploadFiles,
            calculation: state => state.calculation,
        }),
        ...mapGetters([
            'getPriceFormat',
            'visibleSteps',
        ]),
        loading() {
            return this.precalculatedTotalPriceExclVat == null && this.calculation == null && this.errorMessage == null;
        },
        showPriceExclVat() {
            return isTrueOrStringTrue(this.pricingSettings.show_total_vat_excluded);
        },
        totalPriceExclVat() {
            return this.precalculatedTotalPriceExclVat ?? this.calculation?.sales_price_excl_vat;
        },
        showPriceInclVat() {
            return isTrueOrStringTrue(this.pricingSettings.show_total_vat_included);
        },
        totalPriceInclVat() {
            if (this.precalculatedTotalPriceExclVat != null) {
                return this.precalculatedTotalPriceExclVat * VAT_PERCENTAGE;
            }
            return this.calculation?.sales_price_incl_vat;
        },
        requestFilesBeforeAddToCart() {
            return this.uploadRequired || (this.calculation?.upload && this.userWantsToUploadFiles);
        },
    },
    async created() {
        if (this.vendorId !== VENDOR.PROBO) {
            return;
        }

        const calculation = HTTP.post(`/api/configurator/${this.uuid}/calculation`, {
            options: transformInputToProboModel(this.visibleSteps),
        });

        let response;
        try {
            response = await calculation;
        } catch (error) {
            this.errorMessage = error.response?.data?.message ?? 'some error';
        }

        if (response != null) {
            this.$store.commit('setCalculation', response.data);
        }
    },
    methods: {
        getStepInputSummary(step) {
            return step.input?.summary ?? '-';
        },
    },
};
</script>
