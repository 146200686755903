<template>
    <button
        :class="['py-2 px-4 border border-transparent text-sm font-medium text-white transition duration-150 ease-in-out inline-flex items-center ss-button disabled:opacity-50 disabled:cursor-default', { 'rounded-md': style.rounded }]"
        :style="css" @click="$emit('click', $event)">
        <svg width="20" height="20" class="mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             v-if="icon === 'cart'">
            <path
                d="M3 3H5L5.4 5M7 13H17L21 5H5.4M7 13L5.4 5M7 13L4.70711 15.2929C4.07714 15.9229 4.52331 17 5.41421 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM9 19C9 20.1046 8.10457 21 7 21C5.89543 21 5 20.1046 5 19C5 17.8954 5.89543 17 7 17C8.10457 17 9 17.8954 9 19Z"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg class="spinner mr-2" height="20" width="20" viewBox="0 0 100 100" v-if="icon === 'spinner'"
             xmlns="http://www.w3.org/2000/svg">
            <circle stroke="currentColor" cx="50" cy="50" r="45"/>
        </svg>
        <slot></slot>
        <svg class="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-if="icon === 'next'">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
        </svg>
    </button>
</template>

<script>
export default {
    props: {
        icon: String,
    },
    computed: {
        style() {
            return this.$store.getters.styleFor('buttons');
        },
        css() {
            if (!this.style) {
                return '';
            }

            let css = '';
            if (this.style.color) {
                css += `color:${this.style.color};`;
            }
            if (this.style.background) {
                css += `background-color:${this.style.background};`;
            }

            return css;
        },
    },
};
</script>

<style scoped>
svg.spinner {
    animation: 2s linear infinite svg-animation;
    max-width: 100px;
}

@keyframes svg-animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg)
    }
}

circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
}

@keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}
</style>
