<template>
    <div :class="['w-full transform transition-all', { 'max-w-4xl overflow-auto max-h-screen h-full md:h-auto': !integratedMode }]">

        <AppSpinner v-if="loading" color="text-gray-600" size="w-24"/>

        <div v-else :class="['bg-white ss-container', { 'shadow-xl': !integratedMode, 'rounded-lg': !style || style.rounded }]" :style="css">
            <ButtonClose v-if="!integratedMode" @click="$emit('close')"/>

            <PaneError v-if="error" v-bind="error" :show-close-button="!integratedMode" @close="$emit('close')"/>
            <template v-else>
                <div :class="['mx-auto rounded-lg', { 'max-w-4xl p-4 sm:p-6': !integratedMode }]">

                    <AppSpinner v-if="addingToCart">{{ $t('Adding to cart') }}...</AppSpinner>

                    <FileUploader v-else-if="showUploader" :locale="$i18n.locale" @continue="addToCart" />

                    <div v-else class="flex gap-x-4">

                        <div v-if="!stepsOnly" class="hidden md:block w-[256px] space-y-3">
                            <h3 class="text-base font-medium ss-product-name">{{ configuration.product.title[$i18n.locale] }}</h3>
                            <img v-if="configuration.configuration.image_url" class="rounded-xs shadow-sm" :src="configuration.configuration.image_url">
                            <div class="configurator-text" v-html='configuration.settings.texts.container_help'></div>
                        </div>
                        <div :class="{ 'flex-1': !stepsOnly, 'w-full': stepsOnly }">

                            <template v-if="!integratedMode">
                                <h3 :class="['text-lg font-medium mb-3 ss-product-name', { 'md:hidden': !stepsOnly }]">
                                    {{ configuration.product.title[$i18n.locale] }}
                                </h3>
                                <h3 class="text-base font-medium mb-3 ss-customize">
                                    {{ $t('Customize your product') }}
                                </h3>
                            </template>

                            <div class="space-y-[-1px]">
                                <ProductConfiguratorStep v-for="step of visibleSteps"
                                                         :id="'wwastep_' + step._persistant_index"
                                                         :key="step._persistant_index"
                                                         :step="step"
                                                         @submit="handleStepInput"
                                                         @click-heading="() => toggleStepExpanded(step)"
                                />
                            </div>

                            <OrderSummary v-if="showOrderSummary"
                                          class="pt-6 px-4 pb-4"
                                          id="wwastep_summary"
                                          :precalculated-total-price-excl-vat="totalPriceExclVat"
                                          @show-uploader="showUploader = true"
                                          @confirm="addToCart"/>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { VENDOR } from '../constants';
import { HTTP } from '../http-common';
import AppSpinner from './AppSpinner';
import ButtonClose from './ButtonClose';
import FileUploader from './FileUploader';
import OrderSummary from './OrderSummary';
import PaneError from './PaneError';
import ProductConfiguratorStep from './ProductConfiguratorStep';

export default {
    props: {
        uuid: { type: String, required: true },
    },
    components: {
        AppSpinner,
        ButtonClose,
        FileUploader,
        OrderSummary,
        PaneError,
        ProductConfiguratorStep,
    },
    data() {
        return {
            loading: true,
            error: null,
            showOrderSummary: false,
            showUploader: false,
            addingToCart: false,
        };
    },
    computed: {
        ...mapState({
            configuration: state => state.configuration,
            vendorId: state => state.configuration.product.vendor_id,
            currentStepIndex: state => state.currentStepIndex,
            endHasBeenReached: state => state.endHasBeenReached,
            integratedMode: state => state.integratedMode,
            stepsOnly: state => state.configuration.settings.layout === 'steps_only',
            calculationId: state => state.calculation?.id,
        }),
        ...mapGetters([
            'requiredSteps',
            'visibleSteps',
        ]),
        style() {
            return this.$store.getters.styleFor('container');
        },
        css() {
            if (this.style == null) {
                return '';
            }

            let css = '';
            if (this.style.color) {
                css += `color:${this.style.color};`;
            }
            if (this.style.background) {
                css += `background-color:${this.style.background};`;
            }

            return css;
        },
        totalPriceExclVat() {
            if (this.vendorId !== VENDOR.DEGROOT || !this.endHasBeenReached) {
                return null;
            }

            const editionStep = this.visibleSteps.find(step => step.type === 'degroot-edition');
            const deliveryStep = this.visibleSteps.find(step => step.type === 'degroot-delivery');

            if (editionStep == null || deliveryStep == null) {
                return null;
            }

            const editionOptions = this.$store.state.deGrootOrderingOptions.editionOptions;
            const edition = editionOptions.find(option => option.method === editionStep.input?.method && option.quantity === editionStep.input?.quantity);

            const deliveryOptions = this.$store.state.deGrootOrderingOptions.deliveryOptions;
            const delivery = deliveryOptions[edition.method].find(option => option.key === deliveryStep.input?.key)

            return edition.prices[delivery.key];
        },
    },
    async created() {
        this.loading = true;
        this.$store.commit('resetConfigurator');

        let response;
        try {
            response = await HTTP.get(`/api/configurator/${this.uuid}.json`);
        } catch (error) {
            this.error = error.response?.status === 404
                ? { title: this.$t('Product not found.'), message: this.$t(`Sorry, we couldn't find the product you're looking for.`) }
                : this.getGenericErrorProps();
            return;
        } finally {
            this.loading = false;
        }

        try {
            this.$store.commit('setConfiguration', { uuid: this.uuid, ...response.data });
        } catch (error) {
            this.error = this.getGenericErrorProps();
        } finally {
            this.loading = false;
        }
    },
    methods: {
        scrollToStep(step) {
            const stepId = `#wwastep_${step}`;

            setTimeout(() => {
                const stepElement = this.$el.querySelector(stepId);
                const rect = stepElement.getBoundingClientRect();
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                const topPosition = rect.top + scrollTop - 50;

                window.scrollTo({ top: topPosition, behavior: "smooth" });
            }, 100);
        },
        getGenericErrorProps() {
            return {
                title: this.$t('An error occurred.'),
                message: this.$t(`Sorry, something doesn't seem to work. Please try again later.`),
            };
        },
        toggleStepExpanded(step) {
            if (this.currentStepIndex === step._persistant_index) {
                // Clicking the header of the active step collapses it by setting null as the current step.
                this.$store.commit('setCurrentStepIndex', null);
            } else {
                this.$store.commit('setCurrentStepIndex', step._persistant_index);
                this.scrollToStep(step._persistant_index);
            }
        },
        handleStepInput(input) {
            if (typeof input.code === 'string' && input.code.startsWith('syncsilo_upload_files_')) {
                this.$store.commit('setUserWantsToUploadFiles', input.code.endsWith('yes'));
            }

            this.showOrderSummary = false;
            this.$store.commit('setInputForCurrentStep', input);

            const nextStepIndex = this.getNextStepIndex();
            this.$store.commit('setCurrentStepIndex', nextStepIndex);

            if (nextStepIndex === null) {
                this.$store.commit('setEndHasBeenReached', true);
                this.calculatePrice();
                this.scrollToStep('summary');
            } else {
                this.scrollToStep(nextStepIndex);
            }
        },
        getNextStepIndex() {
            const allRequiredStepsAreVisible =
                JSON.stringify(this.requiredSteps.map(step => step._persistant_index)) ===
                JSON.stringify(this.visibleSteps.map(step => step._persistant_index));

            if (this.endHasBeenReached && allRequiredStepsAreVisible) {
                const accessoriesStepIndex = this.getVisibleAccessoriesStepIndexOrNull();
                const alreadyOnAccessoriesStep = this.currentStepIndex != null
                    && this.currentStepIndex === accessoriesStepIndex;

                if (!alreadyOnAccessoriesStep) {
                    // The changed input may yield different available accessories, so show the accessories step once again:
                    return accessoriesStepIndex;
                }
                return null;
            }

            return this.requiredSteps
                .find(step => step._persistant_index > this.currentStepIndex)
                ?._persistant_index ?? null;
        },
        getVisibleAccessoriesStepIndexOrNull() {
            return this.visibleSteps
                .find(step => step.type === 'accessories')
                ?._persistant_index ?? null;
        },
        async addToCart() {
            this.addingToCart = true;

            const minimumDelay = new Promise(resolve => {
                setTimeout(resolve, 1250);
            });

            const addToCartPromise = this.configuration.platform === 'shopify'
                ? this.addToShopifyCart()
                : this.addToWoocommerceCart();

            await Promise.all([minimumDelay, addToCartPromise]);

            this.$emit('close');
        },
        addToShopifyCart() {
            return HTTP.post('/api/configurator/' + this.uuid + '/calculation/' + this.calculationId + '/shopify_cart')
                .then((response) => {
                    window.location.href = '/cart/add/' + response.data.shopify_variant_id;
                }).catch(() => {
            });
        },
        addToWoocommerceCart() {
            const calculationIdField = document.getElementById('wwa_configuration_calculation_id');
            if (calculationIdField) {
                calculationIdField.value = this.calculationId;
            }

            const configurationIdField = document.getElementById('wwa_configuration_id');
            if (configurationIdField) {
                configurationIdField.value = this.uuid;
            }

            let button = null;
            if (document.body.getElementsByClassName('single_add_to_cart_button').length > 0) {
                button = document.body.getElementsByClassName('single_add_to_cart_button')[0];
            } else {
                button = document.body.querySelectorAll('.cart button')[0];
            }
            button?.click();
        },
        async calculatePrice() {
            this.showOrderSummary = false;

            // (Re)show the order summary on the next tick to ensure the created hook is called, recalculating the price.
            this.$nextTick(() => this.showOrderSummary = true);
        },
    },
};
</script>
