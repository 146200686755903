<template>
    <span>{{ labelOrMissing }}</span>
</template>

<script>
import { mapState } from 'vuex';
import { HTTP } from '../http-common';

/**
 * This label is "smart" because it translates itself into the active language
 * if required. It does so by calling our API to fetch the label based on a language
 * that we do have the label for. For example, if we need EN, but only have NL,
 * we fetch the EN label from our API based on the NL text.
 */

export default {
    props: {
        labels: {
            type: Object,
            validator(labels) {
                return Object.entries(labels).every(([locale, label]) => {
                    return ['nl', 'en', 'de'].includes(locale)
                        && (label === undefined || label === null || typeof label === 'string');
                });
            },
        },
        customLabelSubject: String,
    },
    data() {
        const hydratedLabels = {};

        Object.entries(this.labels)
            .forEach(([locale, label]) => hydratedLabels[locale] = label);

        return {
            hydratedLabels,
            loading: false,
            didAttemptHydration: false,
        };
    },
    computed: {
        ...mapState({
            locale: state => state.locale,
            productId: state => state.configuration?.product.id,
        }),
        labelOrMissing() {
            if (this.loading || !this.didAttemptHydration) {
                return this.label;
            }

            // Only when done loading after having attempted to hydrate the given labels
            // should we render "(translation missing)". It's perfectly valid for a label to
            // remain null, e.g. when the label doesn't exist in any language.
            return this.label ?? this.$t('(translation missing)');
        },
        label() {
            if (this.isSet(this.customLabel)) {
                return this.customLabel;
            }
            if (this.isSet(this.supplierLabel)) {
                return this.supplierLabel;
            }
            if (this.isSet(this.hydratedLabel)) {
                return this.hydratedLabel;
            }
            return null;
        },
        customLabel() {
            if (typeof this.customLabelSubject !== 'string') {
                return null;
            }
            return this.$store.getters.customLabel(this.customLabelSubject);
        },
        supplierLabel() {
            const supplierLabel = this.labels[this.locale];

            // Some supplier labels are empty strings, which we want to treat as not having been set (null).
            return this.isSet(supplierLabel) ? supplierLabel : null;
        },
        hydratedLabel() {
            const hydratedLabel = this.hydratedLabels[this.locale];

            return this.isSet(hydratedLabel) ? hydratedLabel : null;
        },
    },
    created() {
        const requestData = {
            labels: this.labels,
            target_language: this.locale,
        };

        this.hydrateFromCache(requestData);
        if (!this.requiresHydration()) {
            return;
        }

        this.didAttemptHydration = true;
        this.fetchFallbackTranslations(requestData);
    },
    watch: {
        labelOrMissing: {
            immediate: true,
            handler(label) {
                if (this.isSet(label)) {
                    // The `labelOrMissing` always ends up with a value: Preferably an actual label,
                    // but can also be the string "(translation missing)" if everything fails.

                    // In other words, this event always fires.
                    this.$emit('label-determined', label);
                }
            },
        },
    },
    methods: {
        hydrateFromCache(requestData) {
            if (!this.requiresHydration()) {
                return;
            }

            const requestJson = JSON.stringify(requestData);
            const cachedResponse = this.$store.state.fallbackTranslationResponsesCache[requestJson];
            if (!cachedResponse) {
                return;
            }

            const language = requestData.target_language;
            this.$set(this.hydratedLabels, language, cachedResponse[language] ?? null);
        },
        async fetchFallbackTranslations(requestData) {
            this.loading = true;
            try {
                const response = await HTTP.post(`/api/products/${this.productId}/fallback-translations`, requestData);
                for (let [locale, translation] of Object.entries(response.data)) {
                    this.$set(this.hydratedLabels, locale, translation);
                }

                this.$store.commit('cacheFallbackTranslationsResponse', {
                    requestJson: JSON.stringify(requestData),
                    response: response.data,
                });
            } catch (error) {
                // Silently ignore, falling back to "(translation missing)"
            } finally {
                this.loading = false;
            }
        },
        requiresHydration() {
            if (this.isSet(this.label)) {
                return false;
            }

            const labelIsSetInAnyLanguage = Object.values(this.labels).some(label => this.isSet(label));
            if (!labelIsSetInAnyLanguage) {
                // No source text to work with, so no point in trying to fetch a translation
                return false;
            }

            return true;
        },
        isSet(value) {
            return typeof value === 'string' && value.trim().length > 0;
        },
    },
};
</script>
