<template>
    <AppSpinner v-if="loading"/>
    <form v-else class="space-y-4" @submit.prevent="submit">
        <div class="flex flex-row flex-wrap gap-4">
<!--            <button class="border border-gray-300 rounded-lg overflow-hidden ss-cross-sell-step relative transition duration-150 md:max-w-[275px] w-full sm:w-[calc(51.1%-16px)]" type="submit"-->
<!--                :style=" hovering ? `border-color:${style.background}` : null"-->
<!--                @click="submit(null, 1)"-->
<!--                @mouseover="hovering = true" @mouseout="hovering = false">-->

<!--                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="mx-auto w-[50%] text-gray-300 p-4"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>-->

<!--                <div class="p-4 text-center">{{ $t('No accessories') }}</div>-->
<!--            </button>-->

            <StepCrossSellsItem v-for="accessory of accessories"
                                :key="accessory.code"
                                v-model.number="quantities[accessory.code]"
                                :accessory="accessory"
                                :price="getAccessoryPriceByCode(accessory.code)"
                                :show-price="showPricing && accessory.code !== 'none'"
                                @input="(value) => updateCrossSellAmount(accessory, value)"
                                @none="updateNone(accessory)"
            />
        </div>
        <AppButton type="submit" icon="next">{{ $t('Next') }}</AppButton>
    </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { HTTP } from '../http-common';
import { transformInputToProboModel } from '../transformInputToProboModel';
import { isTrueOrStringTrue } from '../utilities';
import AppButton from './AppButton';
import AppSpinner from './AppSpinner';
import StepCrossSellsItem from './StepCrossSellsItem';

export default {
    name: 'StepCrossSells',
    components: { AppButton, AppSpinner, StepCrossSellsItem },
    props: {
        step: Object,
    },
    data() {
        return {
            loading: false,
            quantities: {},
            pricing: {},
            availableAccessoryCodes: [],
            hovering: false,
        };
    },
    computed: {
        ...mapState({
            uuid: state => state.configuration.uuid,
            pricingSettings: state => state.configuration.settings.pricing,
        }),
        ...mapGetters([
            'visibleSteps',
        ]),
        accessories() {
            return this.availableAccessoryCodes
                .map(code => this.step.accessories.find(accessory => accessory.code === code))
                .filter(accessory => accessory != null);
        },
        blacklistedAccessoryCodes() {
            return this.blacklistManipulation?.manipulation_value?.blacklist ?? [];
        },
        blacklistManipulation() {
            return this.$store.getters.manipulationsForStepType('accessories-cross-sell')
                .find(manipulation => {
                    return manipulation.manipulation_type === 'visibility'
                        && manipulation.manipulation_value?.visibility === 'blacklist';
                }) || null;
        },
        showPricing() {
            const { show_total_vat_included, show_total_vat_excluded } = this.pricingSettings;

            return isTrueOrStringTrue(this.pricingSettings.show_accessory_pricing)
                && (isTrueOrStringTrue(show_total_vat_included) || isTrueOrStringTrue(show_total_vat_excluded));
        },
        style() {
            return this.$store.getters.styleFor('buttons');
        },
    },
    async created() {
        const stepsExceptSelf = this.visibleSteps
            .filter(step => step._persistant_index !== this.step._persistant_index);

        const postModel = {
            options: transformInputToProboModel(stepsExceptSelf),
        };

        this.loading = true;

        try {
            const response = await HTTP.post(`/api/configurator/${this.uuid}/accessories`, postModel);
            let accessories = response.data;
            if(!Array.isArray(accessories)){
              accessories = Object.values(accessories);
            }
            const accessoryCodes = accessories.map(accessory => accessory.code);

            this.availableAccessoryCodes = accessoryCodes
                .filter(code => !this.blacklistedAccessoryCodes.includes(code));

            // Dedupe because probo sometimes gives us multiple identical codes:
            this.availableAccessoryCodes = [...new Set(this.availableAccessoryCodes)];

            this.pricing = this.getPricingMap(accessories);
            this.quantities = this.getQuantitiesMap(this.availableAccessoryCodes);

            if (this.accessories.length === 0) {
                this.submit(this.$t('No accessories available found'));
            }
        } catch (e){
          console.warn(e)
            // Most likely some condition was not met (probo calls it "restriction")
            this.submit(this.$t('No accessories available'));
        } finally {
            this.loading = false;
        }
    },
    methods: {
        getPricingMap(accessoriesResponse) {
            const pricing = {};
            for (let accessory of accessoriesResponse) {
                pricing[accessory.code] = accessory.pricing;
            }
            return pricing;
        },
        getQuantitiesMap(accessoryCodes) {
            const quantities = {};
            for (let accessoryCode of accessoryCodes) {
                quantities[accessoryCode] = this.step.input?.quantities?.[accessoryCode] ?? 0;
            }
            return quantities;
        },
        getAccessoryPriceByCode(accessoryCode) {
            const { show_total_vat_included, show_total_vat_excluded } = this.pricingSettings;

            if (isTrueOrStringTrue(show_total_vat_included)) {
                return this.pricing[accessoryCode].sales_price_incl_vat;
            }

            if (isTrueOrStringTrue(show_total_vat_excluded)) {
                return this.pricing[accessoryCode].sales_price_excl_vat;
            }

            return null;
        },
        updateNone(){
          this.quantities = {
            'none':1
          }
          this.submit();
        },
        updateCrossSellAmount(accessory, amount) {
            amount = parseInt(amount);
            if (Number.isNaN(amount)) {
                amount = 0;
            }
            this.quantities[accessory.code] = Math.max(0, amount);
        },
        submit(summary = null, clearQuantities = null) {
            const nonZeroQuantities = { ...this.quantities };
            for (let key of Object.keys(nonZeroQuantities)) {
                if (clearQuantities === 1 || nonZeroQuantities[key] === 0) {
                    delete nonZeroQuantities[key];
                }
            }

            if (typeof summary !== 'string') {
                summary = Object.values(nonZeroQuantities).reduce((sum, quantity) => sum + quantity, 0);
            }

            this.$emit('submit', {
                quantities: nonZeroQuantities,
                summary,
            });

            const eventNames = [
                'syncsiloAccessoiresSelected', // event with typo still dispatched for backwards compatibility
                'syncsiloAccessoriesSelected',
            ];
            for (let eventName of eventNames) {
                window.dispatchEvent(new CustomEvent(eventName));
            }
        },
    },
};
</script>
