<template>
    <img v-if="firstImage !== null" :src="firstImage.url" alt="">
</template>

<script>
export default {
    props: {
        images: {
            required: true,
            validator(value) {
                return Array.isArray(value)
                    && value.every(image => image.language != null && image.url != null);
            },
        },
    },
    computed: {
        firstImage() {
            const orderOfPreference = ['all', 'nl', 'en', 'de'];

            for (let language of orderOfPreference) {
                const imageForLanguage = this.images.find(image => image.language === language);
                if (imageForLanguage) {
                    return imageForLanguage;
                }
            }

            return null;
        },
    },
};
</script>
