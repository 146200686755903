import { getTranslations as $t } from './utilities';

const createConditionFromOption = (option) => {
    return {
        input: { code: `value-id:${option['value-id']}` },
        subject: `option-id:${option['option-id']}`,
    };
};

const transformOptionsArrayIntoSteps = (options, conditions = []) => {
    if (options.length === 0) {
        return [];
    }

    const step = {
        conditions,
        type: 'choice',
        title: {
            en: options[0].option, // todo translations
            nl: options[0].option,
            de: options[0].option,
        },
        subject: `option-id:${options[0]['option-id']}`,
        choices: options.map(option => {
            return {
                code: `value-id:${option['value-id']}`,
                ...(option.articlecode != null ? { articlecode: option.articlecode } : {}),
                images: [
                    {
                        language: 'all',
                        url: option.icon.replace('-beta', ''),
                    },
                ],
                translations: {
                    en: { name: option.value }, // todo translations
                    nl: { name: option.value },
                    de: { name: option.value },
                },
            };
        }),
    };

    let childSteps = [];

    for (let option of options) {
        const subOptions = option.options ?? [];
        const childConditions = [...conditions, createConditionFromOption(option)];

        childSteps = [
            ...childSteps,
            ...transformOptionsArrayIntoSteps(subOptions, childConditions),
        ];
    }

    return [step, ...childSteps];
};

const createEditionStep = () => {
    return {
        type: 'degroot-edition',
        title: $t('Edition'),
    };
};

const createDeliveryStep = () => {
    return {
        type: 'degroot-delivery',
        title: $t('Delivery'),
    };
};

const transformDeGrootModelToSteps = (topLevelOptions) => {
    const choiceSteps = transformOptionsArrayIntoSteps(topLevelOptions);

    return [
        ...choiceSteps,
        createEditionStep(),
        createDeliveryStep(),
    ];
};

export { transformDeGrootModelToSteps };
