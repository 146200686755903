<template>
    <div class="wwa-configurator text-base" v-app-initiated="initiate">
        <template v-if="integrated">
            <ProductConfigurator :uuid="configurationId"/>
        </template>
        <template v-else-if="showModalConfigurator">
            <div class="fixed inset-0 flex items-center justify-center" style="z-index:99999;">
                <div class="fixed inset-0 transition-opacity">
                    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                <ProductConfigurator :uuid="configurationId" @close="close"/>
            </div>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Vuex from 'vuex';
import ProductConfigurator from './components/ProductConfigurator';
import { HTTP } from './http-common';
import de from './locales/de.json';
import nl from './locales/nl.json';
import store from './store';

Vue.use(VueI18n);
Vue.use(Vuex);

export default {
    store: new Vuex.Store(store),
    i18n: new VueI18n({
        locale: 'en',
        fallbackLocale: 'en',
        silentFallbackWarn: true,
        formatFallbackMessages: true,
        missing(locale, keypath) {
            // This custom handler ensures we get warnings for missing translations for every locale except EN.
            // We can't use the silentTranslationWarn option (true nor false) because it either hides messages for ALL locales (when true), or (when false) shows warnings even for EN despite using formatFallbackMessages.
            if (locale !== 'en') {
                console.warn(`Can't translate '${keypath}' to ${locale}`);
            }
        },
        messages: { nl, de },
    }),
    props: {
        dataPriceFormat: { type: String },
        api: { type: String },
        uuid: { type: String },
    },
    components: {
        ProductConfigurator,
    },
    data() {
        return {
            showModalConfigurator: false,
            configurationId: null,
            integrated: false, // We need to use local state (in addition to vuex state) for this prop because when there are multiple wwa-configurator elements on the page, one integrated and one modal, they share state, making the modal one think it's integrated as well, but without a uuid, resulting in a call to null.json
            // todo figure out how to better isolate instances of the wwa-configurator custom element
        };
    },
    computed: {
        apiBaseUrl() {
            if (typeof this.api !== 'string' || this.api.trim().length === 0) {
                return 'https://app.syncsilo.com';
            }
            return this.prependSchemeIfMissing(this.api);
        },
        language() {
            const htmlLang = (document.documentElement.getAttribute('lang') ?? '').substr(0, 2).toLowerCase();
            return ['nl', 'de'].includes(htmlLang) ? htmlLang : 'en';
        },
    },
    watch: {
        api: {
            immediate: true,
            handler() {
                // By using this watcher we support dynamic changing of the api attribute on the wwa-configurator element.
                HTTP.defaults.baseURL = this.apiBaseUrl;
            },
        },
        language: {
            immediate: true,
            handler(language) {
                this.$store.commit('setLocale', language);
                this.$i18n.locale = language;
            },
        }
    },
    created() {
        if (this.uuid) {
            this.configurationId = this.uuid;

            if (this.dataPriceFormat) {

              let parsedPriceFormat = JSON.parse(this.dataPriceFormat);
              if(parsedPriceFormat){
                this.$store.commit('setPriceFormat',parsedPriceFormat);
              }
            }

            this.$store.commit('setIntegratedMode', true);
            this.integrated = true;
        }
    },
    methods: {
        prependSchemeIfMissing(api) {
            if (!/^https?:\/\//.test(api)) {
                return 'https://' + api;
            }
            return api;
        },
        close() {
            this.showModalConfigurator = false;
        },
        initiate(event) {
            event.preventDefault();
            this.configurationId = event.target.getAttribute('data-configuration-id');
            const dataPriceFormat = event.target.getAttribute('data-price-format');

            if (dataPriceFormat) {
              let parsedPriceFormat = JSON.parse(dataPriceFormat);
              if(parsedPriceFormat){
                this.$store.commit('setPriceFormat', parsedPriceFormat);
              }
            }

            this.showModalConfigurator = true;
        },
    },
    directives: {
        appInitiated: {
            bind(el, binding, vnode) {
                el.onClickTriggerOpenConfigurator = (event) => {
                    if (event.target.closest('.ww-configurator')) {
                        vnode.context[binding.expression](event);
                    }
                };
                document.body.addEventListener('click', el.onClickTriggerOpenConfigurator);
            },
            unbind(el) {
                document.body.removeEventListener('click', el.onClickTriggerOpenConfigurator);
            },
        },
    },
};
</script>

<style>
@import "./assets/styles/index.css";
</style>
