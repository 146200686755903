const checkCondition = (conditionGrouping, steps) => {
    // The param conditionGrouping is an object (for a single grouping) or an array of objects (for several groupings).
    // A grouping passes only if ALL key/values match against a single step (&&-style).

    // If conditionGrouping is an array, the condition is met if ANY grouping passes (||-style)

    // Example grouping: { subject: 'material', input: 'banner' }
    // Example array of groupings: [{ articlecode: 'abc' }, { articlecode: 'def' }]

    const orGroupings = Array.isArray(conditionGrouping)
        ? conditionGrouping
        : [conditionGrouping];

    for (let step of steps) {
        for (let condition of orGroupings) {
            if (allKeyValuesMatch(condition, step)) {
                return true;
            }
        }
    }

    return false;
};

const allKeyValuesMatch = (condition, step) => {
    for (let [key, value] of Object.entries(condition)) {

        if (typeof value === 'object' && value !== null) {
            // TODO: Use recursion for testing nested objects in the future. For now, this will do
            for (let [nestedKey, nestedValue] of Object.entries(value)) {
                if (!strictEquals(step[key]?.[nestedKey], nestedValue)) {
                    return false;
                }
            }
        } else if (!strictEquals(step[key], value)) {
            return false;
        }
    }
    return true;
};

const strictEquals = (valueA, valueB) => {
    return serialize(valueA) === serialize(valueB);
};

const serialize = (value) => {
    // Only serialize objects, else return the given value as-is for faster(?) comparison
    if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value);
    }
    return value;
};

export { checkCondition };
