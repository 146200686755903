<template>
    <form @submit.prevent="submit" class="space-y-2">
        <div class="flex flex-col md:flex-row md:max-w-xs gap-3">
            <div v-for="dimension of step.options.dimensions" :key="dimension.code" class="flex-1">
                <label class="block text-sm font-medium mb-1">{{ getLabel(dimension.code) }}</label>
                <div class="mt-1 relative rounded-md shadow-sm">
                    <input type="number" v-model.number="model[dimension.code]" lang="nl"
                           :min="computeDimensions[dimension.code+'Min']" :max="computeDimensions[dimension.code+'Max']" step="0.1"
                           ref="inputField"
                           class="border-2 p-2 w-full bg-transparent"
                           :class="'wwa-size-'+[dimension.code]"
                           required>
                    <div class="absolute inset-y-0 pr-3 right-0 flex items-center pointer-events-none">
                        <span class="text-gray-500 sm:text-sm">cm</span>
                    </div>
                </div>
            </div>
        </div>
        <AppButton type="submit" icon="next">{{ $t('Next') }}</AppButton>
    </form>
</template>

<script>
import AppButton from './AppButton'

export default {
  props: {
    step: Object,
  },
  components: { AppButton },
  data () {
    return {
      model: {
        width: this.step.input?.width ?? this.getDefaultValue('width'),
        height: this.step.input?.height ?? this.getDefaultValue('height'),
      },
    };
  },
  mounted () {
    this.$refs.inputField[0]?.select();
  },
  computed: {
    computeDimensions () {

      let ratio = this.model.width < this.model.height ? 'vertical' : 'horizontal';
      return this.renderMinMax(ratio)
    },
  },
  methods: {
    renderMinMax (ratio) {
      const widthDimension = this.getDimension('width')
      const heightDimension = this.getDimension('height')
      let widthMin = null;
      let widthMax = null;
      let heightMin = null
      let heightMax = null

      if (widthDimension) {
        widthMin = widthDimension.min
        widthMax = widthDimension.max
      }

      if (heightDimension) {
        heightMin = heightDimension.min
        heightMax = heightDimension.max
      }

      const maxesRatio = widthMax < heightMax ? 'vertical' : 'horizontal';

      if (this.limitsBreached(widthMax, heightMax) && ratio !== maxesRatio) {
        widthMin = heightDimension.min
        widthMax = heightDimension.max
        heightMin = widthDimension.min
        heightMax = widthDimension.max
      }
      return {widthMin, widthMax, heightMin, heightMax}
    },
    getLabel (dimensionCode) {
      if (dimensionCode === 'width') {
        return this.$t('Width');
      }
      if (dimensionCode === 'height') {
        return this.$t('Height');
      }
      return '';
    },
    getDefaultValue (dimensionCode) {
      const dimensionOptions = this.step.options.dimensions
        .find(({ code }) => code === dimensionCode);

      return dimensionOptions?.default ?? null;
    },
    getDimension(dimensionCode) {
        return this.step.options.dimensions
            .find(({ code }) => code === dimensionCode);
    },
    submit () {
      const input = {};
      if (this.model.width != null) {
        input.width = this.model.width;
      }
      if (this.model.height != null) {
        input.height = this.model.height;
      }

      input.summary = ['width', 'height']
        .map(dimension => this.model[dimension])
        .filter(dimension => dimension != null)
        .join(' x ') + ' cm';

      this.$emit('submit', input);
    },
    limitsBreached (widthMax, heightMax) {
      return (widthMax && this.model.width > widthMax) || (heightMax && this.model.height > heightMax)
    }
  },
};
</script>

<style scoped>
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
}
</style>
