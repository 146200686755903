const transformStepToProboModel = (step) => {

    if (step.input == null) {
        return [];
    }

    if (step.type === 'dimensions') {
        const model = [];

        if (step.input.width != null) {
            model.push({
                code: 'width',
                value: step.input.width.toString(),
            });
        }
        if (step.input.height != null) {
            model.push({
                code: 'height',
                value: step.input.height.toString(),
            });
        }

        return model;
    }

    if (step.type === 'quantity') {
        return [{
            code: 'amount',
            value: step.input.value,
        }];
    }

    if (step.type === 'choice') {
        if (step.input.code.startsWith('syncsilo_')) {
            // a hack to ensure our custom print design step isn't pushed to probo for fetching accessories and calculations (which yields errors)
            return [];
        }

        const model = { code: step.input.code };
        if (step.input.numberInput != null) {
            model.value = step.input.numberInput;
        }
        return [model];
    }

    if (step.type === 'accessories') {
        return Object.entries(step.input.quantities)
            .map(([key, value]) => ({
                code: key,
                value,
            }));
    }

    return [];
};

const transformInputToProboModel = (steps) => {
    let model = [];

    for (let step of steps) {
        model = [...model, ...transformStepToProboModel(step)];
    }

    return model;
};

export { transformInputToProboModel };
