<template>
    <form @submit.prevent="submit" class="space-y-2">
        <div class="max-w-xs">
            <label class="block text-sm font-medium mb-1">{{ $t('Quantity') }}</label>
            <input type="number" v-model.number="quantity" ref="inputField"
                   :min="step.options.min" :max="step.options.max" step="1"
                   class="border-2 p-2 w-full bg-transparent" required>
        </div>

        <AppButton type="submit" icon="next">{{ $t('Next') }}</AppButton>
    </form>
</template>

<script>
import AppButton from './AppButton';

export default {
    components: { AppButton },
    props: {
        step: Object,
    },
    data() {
        return {
            quantity: this.step.input?.value ?? this.step.options.default ?? null,
        };
    },
    mounted() {
        this.$refs.inputField.select();
    },
    methods: {
        submit() {
            this.$emit('submit', {
                value: this.quantity,
                summary: this.quantity,
            });
        },
    },
};
</script>

<style scoped>
input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
}
</style>
