<template>
    <div>
        <div>
            <div
                :class="['border border-gray-300 text-gray-900 px-4 py-3 cursor-pointer ss-step-heading transition duration-200', { 'rounded-t-md': style.rounded, 'rounded-b-md': style.rounded && !isCurrentStep }]"
                :style="css" @click.prevent="$emit('click-heading')">
                <div class="flex items-center">
                    <div class="flex items-center">
                        <template v-if="style.icons">
                            <!-- chevron-down (outline) -->
                            <svg v-if="inputSummary == null || isCurrentStep" width="24" class="mr-4" height="24" fill="none" stroke-linecap="round"
                                 stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                                <path d="M19 9l-7 7-7-7"></path>
                            </svg>

                            <!-- tick mark with circle (unable to find hero-icons name) -->
                            <svg v-else width="24" class="mr-4" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </template>
                        <SmartLabel class="mr-1" :labels="step.title"/>
                    </div>
                    <span v-if="inputSummary != null" class="ml-2 text-sm">{{ inputSummary }}</span>
                </div>
            </div>
        </div>

        <template v-if="isCurrentStep">
            <div v-if="loading" class="p-3 sm:p-6 rounded-xs ss-step">
                <svg class="spinner mr-2" height="20" width="20" viewBox="0 0 100 100"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle stroke="currentColor" cx="50" cy="50" r="45"/>
                </svg>
            </div>

            <component v-else :is="stepComponent" :step="step" :value="value" @submit="$emit('submit', $event)"
                       :class="['p-3 sm:p-6 border-gray-300 border border-t-0 ss-step', { 'rounded-b-md': style.rounded }]"/>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import SmartLabel from './SmartLabel';
import StepCrossSells from './StepCrossSells';
import StepDeGrootDelivery from './StepDeGrootDelivery';
import StepDeGrootEdition from './StepDeGrootEdition';
import StepQuantity from './StepQuantity';
import StepChoice from './StepChoice';
import StepSize from './StepSize';

export default {
    props: {
        step: Object,
        value: Object,
    },
    components: {
        SmartLabel,
        StepCrossSells,
        StepChoice,
        StepQuantity,
        StepSize,
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapState({
            currentStepIndex: state => state.currentStepIndex,
        }),
        style() {
            return this.$store.getters.styleFor('steps');
        },
        isCurrentStep() {
            return this.currentStepIndex === this.step._persistant_index;
        },
        stepComponent() {
            if (this.step.type === 'dimensions') {
                return StepSize;
            }

            if (this.step.type === 'quantity') {
                return StepQuantity;
            }

            if (this.step.type === 'choice') {
                return StepChoice;
            }

            if (this.step.type === 'accessories') {
                return StepCrossSells;
            }

            if (this.step.type === 'degroot-edition') {
                return StepDeGrootEdition;
            }

            if (this.step.type === 'degroot-delivery') {
                return StepDeGrootDelivery;
            }

            return 'div';
        },
        inputSummary() {
            return this.step.input?.summary ?? null;
        },
        css() {
            if (!this.style) {
                return null;
            }

            let css = '';
            if (this.style.color) {
                css += `color:${this.style.color};`;
            }
            if (this.style.background) {
                css += `background-color:${this.style.background};`;
            }

            return css;
        },
    },
};
</script>

<style scoped>
svg.spinner {
    animation: 2s linear infinite svg-animation;
    max-width: 100px;
}

@keyframes svg-animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg)
    }
}

circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
}

@keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}
</style>
