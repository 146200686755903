<template>
    <AppSelectable :selected="selected" @selected="$emit('selected', deliveryServiceTitle)">
        <div class="p-4">
            <div class="text-center text-lg mt-1 mb-4">{{ deliveryServiceTitle }}</div>

            <p>{{ deliveryOption.delivery_date }}</p>
            <p class="font-light text-sm" :style="`color:${style.color}`">
                <template v-if="price === 0">{{ $t('Free shipping') }}</template>
                <template v-else>+ {{ formatCurrency(price, getPriceFormat) }}</template>
            </p>

        </div>
    </AppSelectable>
</template>

<script>
import { mapGetters } from 'vuex';
import { VAT_PERCENTAGE } from '../constants';
import { formatCurrencyMixin } from '../mixins';
import AppSelectable from './AppSelectable';

export default {
    components: {
        AppSelectable,
    },
    mixins: [formatCurrencyMixin],
    props: {
        deliveryOption: { type: Object, required: true },
        priceExclVat: { type: Number, required: true },
        selected: { type: Boolean, required: true },
    },
    computed: {
        ...mapGetters([
            'getPriceFormat',
            'preferPriceInclVat',
        ]),
        deliveryServiceTitle() {
            return {
                Standaard: this.$t('Standard'),
                Express: this.$t('Express'),
                NextDay: this.$t('ASAP'),
            }[this.deliveryOption.key];
        },
        style() {
            return this.$store.getters.styleFor('container');
        },
        price() {
            return this.preferPriceInclVat
                ? this.priceExclVat * VAT_PERCENTAGE
                : this.priceExclVat;
        },
    },
};
</script>
