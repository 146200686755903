<template>
    <div class="space-y-3">
        <svg :class="['mx-auto', color, size]" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <title>{{ $t('Loading') }}</title>
            <circle cx="50" cy="50" r="45" stroke="currentColor"/>
        </svg>
        <p v-if="$slots.default" class="text-center text-sm text-gray-500">
            <slot/>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        color: { type: String, default: 'text-gray-400' },
        size: { type: String, default: 'w-16' },
    },
};
</script>

<style scoped>
svg {
    animation: 2s linear infinite svg-animation;
}

@keyframes svg-animation {
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(360deg)
    }
}

circle {
    animation: 1.4s ease-in-out infinite both circle-animation;
    display: block;
    fill: transparent;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 10px;
    transform-origin: 50% 50%;
}

@keyframes circle-animation {
    0%,
    25% {
        stroke-dashoffset: 280;
        transform: rotate(0);
    }
    50%,
    75% {
        stroke-dashoffset: 75;
        transform: rotate(45deg);
    }
    100% {
        stroke-dashoffset: 280;
        transform: rotate(360deg);
    }
}
</style>
