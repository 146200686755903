<template>
    <div class="py-16">
        <div class="text-center">
            <h1 class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">{{ title }}</h1>
            <p class="mt-2 text-base text-gray-600">{{ message }}</p>
            <div v-if="showCloseButton" class="mt-6">
                <a href="#" class="text-base font-medium text-indigo-600 hover:text-indigo-500" @click.prevent="close">
                    {{ $t('Close') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        message: { type: String, required: true },
        showCloseButton: { type: Boolean, required: true },
    },
    methods: {
        close(event) {
            this.$emit('close', event);
        },
    },
};
</script>
