import de from './locales/de.json';
import nl from './locales/nl.json';

const getTranslations = (englishText) => {
    // This function can be imported as $t to fool the i18n:report into thinking its own $t is used, leading to a more useful report.
    // It returns an object with all available translations because at this point we don't know the locale yet.
    return {
        en: englishText,
        nl: nl[englishText],
        de: de[englishText],
    };
};

const isTrueOrStringTrue = (value) => {
    // Some parts of the config used to contain strings "true" or "false" for would-be-booleans.
    // The config is only updated (to actual booleans in this case) when a user saves the config, so this utility exists for legacy reasons).
    return value === true || value === 'true';
};

const parseFloatOrNull = (value) => {
    const float = parseFloat(value);
    if (Number.isNaN(float)) {
        return null;
    }
    return float;
};

export { getTranslations, isTrueOrStringTrue, parseFloatOrNull };
