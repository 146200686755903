<template>
    <div class="border border-gray-300 rounded-lg cursor-pointer overflow-hidden transition duration-200 md:max-w-[275px] w-full sm:w-[calc(51.1%-16px)]"
         :style="selected || hovering ? `border-color:${style.background}` : null"
         @mouseover="hovering = true" @mouseout="hovering = false"
         @click="$emit('selected')"
    >
        <TagSelected v-if="selected"/>
        <slot/>
    </div>
</template>

<script>
import TagSelected from './TagSelected';

export default {
    components: {
        TagSelected,
    },
    props: {
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            hovering: false,
        };
    },
    computed: {
        style() {
            return this.$store.getters.styleFor('buttons');
        },
    },
};
</script>
