<template>
    <div>
        <h3 class="text-xl font-medium mb-3 text-gray-900">{{ $t('Upload design file(s)') }}</h3>

        <div class="mx-auto" ref="uploader"></div>

        <p class="text-sm text-center mt-2 mb-4">
            {{ $t('Upload your PDF-, JPG- or PNG design files (maximum of 2 GB per file).') }}
        </p>

        <div class="text-center">
            <AppButton :disabled="!uploadSuccessful" icon="cart" @click.prevent="$emit('continue')" >
                {{ $t('Add to cart') }}
            </AppButton>
        </div>

        <p class="mt-6 text-sm text-center">{{ $t('Do you want to add your design files at a later moment?') }}<br>
            <a class="underline" @click.prevent="$emit('continue')"
               href="#">{{ $t('Click here to add the item to your cart without uploading design files.') }}</a>
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AppButton from './AppButton';

const Uppy = require('@uppy/core');
const AwsS3Multipart = require('@uppy/aws-s3-multipart');
const Dashboard = require('@uppy/dashboard');
const Dutch = require('@uppy/locales/lib/nl_NL');
const German = require('@uppy/locales/lib/de_DE');
const English = require('@uppy/locales/lib/en_US');

export default {
    props: ['locale'],
    components: { AppButton },
    data() {
        return {
            uppy: null,
            uploadSuccessful: false,
        };
    },
    computed: {
        ...mapState({
            calculationId: state => state.calculation.id,
        }),
    },
    mounted() {

        let locale = Dutch;

        if (this.locale === 'de') {
            locale = German;
        } else if (this.locale === 'en') {
            locale = English;
        }

        this.uppy = Uppy({
            debug: false,
            autoProceed: false,
            meta: {
                calculation: this.calculationId,
            },
            restrictions: {
                maxFileSize: 1024 * 2048 * 1000,
                maxNumberOfFiles: 1,
                minNumberOfFiles: 1,
                allowedFileTypes: ['image/*', 'application/pdf'],
            },
        })
            .use(Dashboard, {
                showLinkToFileUploadResult: false,
                inline: true,
                target: this.$refs.uploader,
                replaceTargetContent: true,
                showProgressDetails: true,
                height: 330,
                width: '100%',
                proudlyDisplayPoweredByUppy: false,
                browserBackButtonClose: true,
                locale: locale,
            })
            .use(AwsS3Multipart, {
                companionUrl: 'https://companion.syncsilo.com/',
            });

        this.uppy.on('upload-success', () => {
            this.uploadSuccessful = true;
        });
    },
};
</script>
