<template>
    <div class="relative">
        <div class="absolute h-12 w-12 -top-6 -right-6 rotate-45" :style="`background-color:${style.background}`"/>
        <svg xmlns="http://www.w3.org/2000/svg" class="absolute top-0 right-0 h-5 w-5 text-white"
             viewBox="0 0 20 20" :fill="style.color">
            <path fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"/>
        </svg>
    </div>
</template>

<script>
export default {
    computed: {
        style() {
            return this.$store.getters.styleFor('buttons');
        },
    },
};
</script>
