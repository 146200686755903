import { appendFlat } from './array-functions';
import { getTranslations as $t, parseFloatOrNull } from './utilities';

const getConditionsArray = (questionNode, parentAnswerNode) => {
    // For now, the only supported conditions are those based on nesting inside type_code "parent" nodes using the parentAnswerNode argument

    if (parentAnswerNode === null) {
        return [];
    }

    const parentCondition = {
        input: {
            code: parentAnswerNode.code,
        },
        subject: parentAnswerNode._parent_question_code,
    };

    return [parentCondition];
};

const parseQuestionNode = (questionNode, conditions = [], parentAnswerNode = null) => {
    conditions = [...conditions, ...getConditionsArray(questionNode, parentAnswerNode)];

    const stepBaseProps = {};
    if (Array.isArray(conditions) && conditions.length > 0) {
        stepBaseProps.conditions = conditions;
    }

    // let accessories = parseAccessories(questionNode);

    if (questionNode.type_code === 'parent_size') {
        const dimensionNodes = questionNode.children;

        const dimensions = ['width', 'height']
            .map(dimension => dimensionNodes.find(node => node.code === dimension))
            .filter(node => node != null)
            .map(node => {
                return {
                    code: node.code,
                    min: parseFloatOrNull(node.min_value) ?? null,
                    max: parseFloatOrNull(node.max_value) ?? null,
                    default: parseFloatOrNull(node.default_value) ?? null,
                };
            });

        return {
            steps: [{
                ...stepBaseProps,
                type: 'dimensions',
                title: $t('Dimensions'),
                options: {
                    dimensions,
                },
            }],
            // accessories,
        };
    }

    if (questionNode.type_code === 'parent_amount') {
        const amountNode = questionNode.children[0];

        return {
            steps: [{
                ...stepBaseProps,
                type: 'quantity',
                title: $t('Quantity'),
                options: {
                    min: parseFloatOrNull(amountNode.min_value) ?? 1,
                    max: parseFloatOrNull(amountNode.max_value) ?? null,
                    default: parseFloatOrNull(amountNode.default_value) ?? 1,
                },
            }],
            // accessories,
        };
    }


    if (questionNode.type_code === 'parent') {
        const answers = questionNode.children;
        if(typeof answers === 'undefined'){
            console.warn('Question should be a parent but no answers available', questionNode);
            return {steps:null};
        }
        for (let answer of answers) {
            answer._parent_question_code = questionNode.code;
        }

        const hasCalculatedAnswers = answers.some(answer => answer.type_code === 'calculated');

        let steps = [{
            ...stepBaseProps,
            type: 'choice',
            title: {
                en: questionNode.translations?.en?.name ?? '',
                nl: questionNode.translations?.nl?.name ?? '',
                de: questionNode.translations?.de?.name ?? '',
            },
            subject: questionNode.code,
            choices: answers.map(node => {
                let numberQuery = null;

                if (node.type_code === 'number' || node.type_code === 'decimal') {
                    numberQuery = {
                        min: parseFloatOrNull(node.min_value) ?? null,
                        max: parseFloatOrNull(node.max_value) ?? null,
                        step: parseFloatOrNull(node.step_size) ?? 1,
                        default: parseFloatOrNull(node.default_value),
                    };
                }

                return {
                    code: node.code,
                    images: node.images,
                    translations: node.translations,
                    numberQuery,
                };
            }),
            calculated: hasCalculatedAnswers,
        }];

        for (let answer of answers) {
            for (let subQuestionNode of answer.children ?? []) {
                const {
                    steps: stepsToAppend,
                    // accessories: accessoriesToAppend,
                } = parseQuestionNode(subQuestionNode, conditions, answer);

                steps = appendFlat(steps, stepsToAppend);
                // accessories = appendFlat(accessories, accessoriesToAppend);
            }
        }

        return { steps };
    }

     if (questionNode.type_code === 'parent_cross_sell_onclick' || questionNode.type_code === 'parent_cross_sell_sendtobottom') {
        const answers = questionNode.children;
        for (let answer of answers) {
            answer._parent_question_code = questionNode.code;
        }

        const hasCalculatedAnswers = answers.some(answer => answer.type_code === 'calculated');

        let steps = [{
            ...stepBaseProps,
            type: 'accessories',
            title: {
                en: questionNode.translations?.en?.name ?? '',
                nl: questionNode.translations?.nl?.name ?? '',
                de: questionNode.translations?.de?.name ?? '',
            },
            subject: questionNode.code,
            accessories: answers.map(node => {
                let numberQuery = null;

                if (node.type_code === 'number' || node.type_code === 'decimal') {
                    numberQuery = {
                        min: parseFloatOrNull(node.min_value) ?? null,
                        max: parseFloatOrNull(node.max_value) ?? null,
                        step: parseFloatOrNull(node.step_size) ?? 1,
                        default: parseFloatOrNull(node.default_value),
                    };
                }

                return {
                    code: node.code,
                    images: node.images,
                    translations: node.translations,
                    numberQuery,
                };
            }),
            calculated: hasCalculatedAnswers,
        }];

        for (let answer of answers) {
            for (let subQuestionNode of answer.children ?? []) {
                const {
                    steps: stepsToAppend,
                    // accessories: accessoriesToAppend,
                } = parseQuestionNode(subQuestionNode, conditions, answer);

                steps = appendFlat(steps, stepsToAppend);
                // accessories = appendFlat(accessories, accessoriesToAppend);
            }
        }

        return { steps };
    }

    return {  };
};

// const parseAccessories = (questionNode) => {
//     if (['parent_cross_sell_onclick', 'parent_cross_sell_sendtobottom'].includes(questionNode.type_code)) {
//         return questionNode.children
//             .filter(node => node.type_code === 'cross_sell_pc')
//             .map(node => ({
//                 code: node.code,
//                 images: node.images,
//                 translations: node.translations,
//             }));
//     }
//
//     return [];
// };

const transformProboModelToSteps = (topLevelQuestionNodes) => {
    let allSteps = [];
    let allAccessories = [];

    for (let questionNode of topLevelQuestionNodes) {

        const { steps, accessories } = parseQuestionNode(questionNode);
        allSteps = appendFlat(allSteps, steps);
        allAccessories = appendFlat(allAccessories, accessories);
    }

    if (allAccessories.length > 0) {
        allSteps.push({
            type: 'accessories',
            title: $t('Accessories'),
            accessories: allAccessories,
        });
    }

    return allSteps;
};

export { transformProboModelToSteps };
