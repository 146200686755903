<template>
    <AppSelectable :selected="selected" @selected="$emit('selected')">
        <div class="p-4 space-y-3">
            <div class="text-center text-2xl font-light">{{ formatInteger(edition.quantity) }}</div>
            <div class="text-center">{{ formatCurrency(price, getPriceFormat) }}</div>
        </div>
    </AppSelectable>
</template>

<script>
import { mapGetters } from 'vuex';
import { VAT_PERCENTAGE } from '../constants';
import { formatCurrencyMixin, formatIntegerMixin } from '../mixins';
import { isTrueOrStringTrue } from '../utilities';
import AppSelectable from './AppSelectable';

export default {
    components: {
        AppSelectable,
    },
    mixins: [formatCurrencyMixin, formatIntegerMixin],
    props: {
        edition: { type: Object, required: true }, // with string:method, number:quantity and object:prices (a map)
        selected: { type: Boolean, required: true },
    },
    data() {
        return {
            hovering: false,
        };
    },
    computed: {
        ...mapGetters([
            'getPriceFormat',
            'preferPriceInclVat',
        ]),
        style() {
            return this.$store.getters.styleFor('buttons');
        },
        showPriceExclVat() {
            return isTrueOrStringTrue(this.pricingSettings.show_total_vat_excluded);
        },
        price() {
            return this.preferPriceInclVat
                ? this.edition.prices.Standaard * VAT_PERCENTAGE
                : this.edition.prices.Standaard;
        },
    },
};
</script>
