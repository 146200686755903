<template>
    <AppSpinner v-if="loading"/>
    <div v-else-if="error" class="space-y-4">
        <p>{{ $t('An error occurred.' )}}</p>
        <p>{{ $t('The combination of selected options may not be valid. Please try again with different input.') }}</p>
    </div>
    <div v-else class="flex flex-row flex-wrap gap-4">
        <StepChoiceOption v-for="choice of step.choices"
                          v-show="!blacklistedChoiceCodes.includes(choice.code)"
                          :key="choice.code"
                          :choice="choice"
                          :selected="inputCode === choice.code"
                          :initial-number-input="getNumberInputForChoice(choice)"
                          :auto-focus="step.choices.length === 1"
                          @submit="input => $emit('submit', input)"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { HTTP } from '../http-common';
import { transformInputToProboModel } from '../transformInputToProboModel';
import AppSpinner from './AppSpinner';
import StepChoiceOption from './StepChoiceOption';

export default {
    components: { AppSpinner, StepChoiceOption },
    props: {
        step: { type: Object, required: true },
    },
    data() {
        return {
            loading: false,
            error: false,
        };
    },
    computed: {
        ...mapState({
            uuid: state => state.configuration.uuid,
        }),
        ...mapGetters([
            'useNarrowStepLayout',
            'visibleSteps',
        ]),
        inputCode() {
            return this.step.input?.code ?? null;
        },
        blacklistedChoiceCodes() {
          return this.blacklistManipulation?.manipulation_value?.blacklist ?? [];
        },
        blacklistManipulation() {
          return this.$store.getters.manipulationsForStepType(this.step.subject)
              .find(manipulation => {
                return manipulation.manipulation_type === 'visibility'
                    && manipulation.manipulation_value?.visibility === 'blacklist';
              }) || null;
        },
    },
    async created() {
        if (!this.step.calculated) {
            return;
        }

        const stepsExceptSelf = this.visibleSteps
            .filter(step => step._persistant_index !== this.step._persistant_index);

        const postModel = {
            options: transformInputToProboModel(stepsExceptSelf),
        };

        this.loading = true;

        try {
            const response = await HTTP.post(`api/configurator/${this.uuid}/recalculation`, postModel);

            const newChoices = response.data.children.map(node => ({
                code: node.code,
                translations: node.translations,
            }));

            this.$store.commit('updateStep', {
                stepIndex: this.step._persistant_index,
                newStep: {
                    ...this.step,
                    choices: newChoices,
                },
            });
        } catch (error) {
            this.error = true;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        getNumberInputForChoice(choice) {
            if (choice.code === this.step.input?.code) {
                return this.step.input?.numberInput;
            }
            return null;
        },
    },
};
</script>
