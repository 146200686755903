<template>
    <AppSelectable :selected="selected" @selected="select">
        <ImageFromSet v-if="Array.isArray(choice.images) && choice.images.length > 0"
                      :images="choice.images"
                      class="mx-auto w-full"/>

        <div class="p-4 space-y-2">
            <SmartLabel
                :labels="supplierLabels.name"
                :custom-label-subject="choice.code + '.name'"
                @label-determined="determinedLabels.name = $event"
            />
            <p
                v-show="determinedLabels.description !== null"
                class="text-sm text-gray-600"
                :class="{ 'inline': descriptionStartsWithComma }"
            >
                <SmartLabel
                    :labels="supplierLabels.description"
                    :custom-label-subject="choice.code + '.description'"
                    @label-determined="determinedLabels.description = $event"
                />
            </p>

            <form v-if="choice.numberQuery != null" class="flex gap-x-2 max-w-xs" @submit.prevent="submit">
                <input ref="numberField" class="min-w-0 p-2 border-2 bg-transparent"
                       type="number" v-model.number="numberInput" required
                       :min="choice.numberQuery.min" :max="choice.numberQuery.max" :step="choice.numberQuery.step">
                <AppButton type="submit" class="whitespace-nowrap">{{ $t('Next') }}</AppButton>
            </form>
        </div>
    </AppSelectable>
</template>

<script>
import AppButton from './AppButton';
import AppSelectable from './AppSelectable';
import ImageFromSet from './ImageFromSet';
import SmartLabel from './SmartLabel';

export default {
    components: {
        AppButton,
        AppSelectable,
        ImageFromSet,
        SmartLabel,
    },
    props: {
        choice: { type: Object, required: true },
        selected: { type: Boolean, required: true },
        initialNumberInput: Number,
        autoFocus: Boolean,
    },
    data() {
        const numberInput = this.initialNumberInput ?? null;

        return {
            numberInput,
            setDefaultNumberInputOnFocus: numberInput === null,
            hovering: false,
            determinedLabels: { name: null, description: null },
        };
    },
    computed: {
        style() {
            return this.$store.getters.styleFor('buttons');
        },
        supplierLabels() {
            return {
                name: this.getLabelsByLocale('name'),
                description: this.getLabelsByLocale('description'),
            };
        },
        customLabels() {
            return {
                name: this.$store.getters.customLabel(`${this.choice.code}.name`),
                description: this.$store.getters.customLabel(`${this.choice.code}.description`),
            };
        },
        descriptionStartsWithComma() {
            // Our own initial print step (whether the customer has own designs) has a descriptions that start with a comma, in which case we don't want a line break.
            const { description } = this.determinedLabels;
            return typeof description === 'string' && description.startsWith(',');
        },
    },
    mounted() {
        if (this.autoFocus) {
            this.$refs.numberField?.select();
        }
    },
    methods: {
        select() {
            if (this.determinedLabels.name === null) {
                // While the name (title) of this option is being fetched, prevent the option getting selected.
                // Or else the title would be empty in the summary, leading to weird UI.
                return;
            }

            if (this.choice.numberQuery != null) {
                this.setDefaultNumberInput();
                this.$refs.numberField?.select();
                return;
            }

            this.submit();
        },
        setDefaultNumberInput() {
            if (!this.setDefaultNumberInputOnFocus) {
                return;
            }
            this.numberInput = this.choice.numberQuery?.default ?? null;
            this.setDefaultNumberInputOnFocus = false;
        },
        submit() {
            let summary = this.determinedLabels.name;
            if (this.numberInput != null) {
                summary += ` (${this.numberInput})`;
            }

            const input = {
                summary,
                code: this.choice.code,
                ...(this.numberInput != null ? { numberInput: this.numberInput } : {}),
                ...(this.choice.articlecode != null ? { articlecode: this.choice.articlecode } : {}),
            };
            this.$emit('submit', input);
        },
        getLabelsByLocale(property) {
            if (!['name', 'description'].includes(property)) {
                throw Error('Invalid property to get label by locale for.');
            }

            const labelsByLocale = {};
            Object.entries(this.choice.translations ?? {})
                .forEach(([locale, labels]) => labelsByLocale[locale] = labels[property]);

            return labelsByLocale;
        },
    },
};
</script>

