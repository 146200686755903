<template>
    <AppSpinner v-if="loading">{{ $t('Calculating') }}...</AppSpinner>
    <div v-else :class="['grid gap-3', { 'grid-cols-4': !useNarrowStepLayout, 'grid-cols-3': useNarrowStepLayout }]">

        <StepDeGrootEditionOption v-for="edition of editionOptions"
                                  :key="edition.method + ':' + edition.quantity"
                                  :edition="edition"
                                  :selected="methodInput === edition.method && quantityInput === edition.quantity"
                                  @selected="select(edition)"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { HTTP } from '../http-common';
import AppSpinner from './AppSpinner';
import StepDeGrootEditionOption from './StepDeGrootEditionOption';

export default {
    components: {
        AppSpinner,
        StepDeGrootEditionOption,
    },
    props: {
        step: { type: Object, required: true },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapState({
            uuid: state => state.configuration.uuid,
            editionOptions: state => state.deGrootOrderingOptions?.editionOptions ?? null,
        }),
        ...mapGetters([
            'useNarrowStepLayout',
            'visibleSteps',
        ]),
        articleCode() {
            return this.visibleSteps.find(step => step.input.articlecode != null).input.articlecode;
        },
        methodInput() {
            return this.step.input?.method;
        },
        quantityInput() {
            return this.step.input?.quantity;
        },
    },
    async created() {
        // todo handle errors
        this.loading = true;

        const url = `/api/configurator/${this.uuid}/degroot-ordering-options?articlecode=${this.articleCode}`;
        const orderingOptions = (await HTTP.get(url)).data;

        this.$store.commit('setDeGrootOrderingOptions', {
            editionOptions: orderingOptions.edition_options,
            deliveryOptions: orderingOptions.delivery_options,
        });

        this.loading = false;
    },
    methods: {
        select(edition) {
            const input = {
                method: edition.method,
                quantity: edition.quantity,
                summary: `${edition.quantity} ${this.$t('pieces')}`,
            };

            const inputIsDifferent = JSON.stringify(input) !== JSON.stringify(this.step.input);
            if (inputIsDifferent) {
                // Delivery options depend on the print method and quantity, so unset the delivery input:
                this.$store.commit('unsetInputForStepsOfType', 'degroot-delivery');
            }

            this.$emit('submit', input);
        },
    },
};
</script>
