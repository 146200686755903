import Vue from 'vue';
import { intersect } from './array-functions';
import { checkCondition } from './checkCondition';
import { transformDeGrootModelToSteps } from './transformDeGrootModelToSteps';
import { transformProboModelToSteps } from './transformProboModelToSteps';
import { isTrueOrStringTrue } from './utilities';

export default {
    state: {
        configuration: null,
        currentStepIndex: null,
        stepIndexesThatHaveBeenReached: [],
        endHasBeenReached: false,
        integratedMode: false,
        calculation: null,
        userWantsToUploadFiles: null,
        deGrootOrderingOptions: null,
        locale: null, // 'nl' | 'en' | 'de'
        fallbackTranslationResponsesCache: {},
        priceFormat: {
            currency: "€",
            decimal_separator: ',',
            position: "left_space",
            thousand_separator: '.'
        },
    },
    getters: {
        getPriceFormat(state){
            return state.priceFormat
        },
        steps(state) {
            return state.configuration?.steps ?? [];
        },
        visibleSteps(state, getters) {
            const hasBeenReached = (step) => {
                return state.stepIndexesThatHaveBeenReached.includes(step._persistant_index);
            };

            return intersect(
                getters.requiredSteps,
                getters.steps.filter(hasBeenReached),
            );
        },
        requiredSteps(_, getters) {
            return intersect(
                getters.stepsForWhichConditionsAreMet,
                getters.stepsNotHiddenByManipulation,
            );
        },
        stepsForWhichConditionsAreMet(_, getters) {
            return getters.steps.filter(getters.conditionsForStepAreMet);
        },
        stepsNotHiddenByManipulation(_, getters) {
            return getters.steps.filter(step => {
                if (step.type === 'accessories') {
                    const manipulationStepCode = 'accessories-cross-sell';
                    return !getters.hiddenStepCodes.includes(manipulationStepCode);
                }
                return true;
            });
        },
        conditionsForStepAreMet(_, getters) {
            return (step) => {
                for (let condition of step.conditions ?? []) {
                    if (!checkCondition(condition, getters.steps)) {
                        return false;
                    }
                }
                return true;
            };
        },
        hiddenStepCodes(_, getters) {
            return getters.manipulations
                .filter(manipulation => {
                    return manipulation.manipulation_type === 'visibility'
                        && manipulation.manipulation_value?.visibility === 'hide';
                })
                .map(manipulation => manipulation.manipulation_step);
        },
        translationsForCurrentLocale(state) {
            return (state.configuration?.translations ?? []).filter(({ language }) => language === state.locale);
        },
        customLabel(_, getters) {
            return (subject) => {
                return getters.translationsForCurrentLocale
                    .find(translation => translation.subject === subject)
                    ?.label ?? null;
            };
        },
        manipulationsForStepType(_, getters) {
            return (stepType) => getters.manipulations
                .filter(({ manipulation_step }) => manipulation_step === stepType);
        },
        manipulations(state) {
            return state.configuration?.manipulations ?? [];
        },
        useNarrowStepLayout(state) {
            if (state.integratedMode) {
                return true;
            }
            return state.configuration?.settings.layout !== 'steps_only' ?? false; // an image is displayed on the left, leaving less space for the steps
        },
        styleFor(state) {
            return (subject) => {
                if (!['container', 'steps', 'buttons', 'scroll'].includes(subject)) {
                    return null;
                }
                return state.configuration?.settings.style[subject] ?? null;
            };
        },
        preferPriceInclVat(state) {
            const { show_total_vat_excluded, show_total_vat_included } = state.configuration.settings.pricing;

            if (isTrueOrStringTrue(show_total_vat_included)) {
                return true;
            }

            if (!isTrueOrStringTrue(show_total_vat_excluded)) {
                // Both incl and excl vat are turned off. This makes no sense most of the time, so in this case we prefer showing the price incl vat.
                return true;
            }

            return false;
        },
    },
    mutations: {
        resetConfigurator(state) {
            state.configuration = null;
            state.currentStepIndex = null;
            state.stepIndexesThatHaveBeenReached = [];
            state.endHasBeenReached = false;
            state.calculation = null;
            state.userWantsToUploadFiles = null;
        },
        setConfiguration(state, configuration) {
            state.configuration = configuration;

            if (state.configuration.attributes) {
                // Back-end returns attributes? Then transform the structure on the front-end.

                const transformer = {
                    2: transformProboModelToSteps,
                    4: transformDeGrootModelToSteps,
                }[configuration.product.vendor_id] ?? null;

                if (transformer === null) {
                    console.warn('No transformer defined for vendor_id ' + configuration.product.vendor_id);
                }

                Vue.set(state.configuration, 'steps', transformer(state.configuration.attributes));
                delete state.configuration.attributes;
            }

            let i = 0;
            for (let step of state.configuration.steps) {
                step._persistant_index = i++;
            }

            if (state.configuration.style.custom_css) {
                let style = document.createElement('style');
                style.innerHTML = state.configuration.style.custom_css;
                document.querySelector('wwa-configurator').shadowRoot.appendChild(style);
            }

            state.currentStepIndex = 0;
            state.stepIndexesThatHaveBeenReached = [0];
        },
        setCurrentStepIndex(state, stepIndex) {
            if (stepIndex != null && !state.stepIndexesThatHaveBeenReached.includes(stepIndex)) {
                state.stepIndexesThatHaveBeenReached.push(stepIndex);
            }
            state.currentStepIndex = stepIndex;
        },
        setInputForCurrentStep(state, input) {
            const { steps } = state.configuration;
            const currentStep = steps[state.currentStepIndex];

            const previousInput = currentStep.input ?? null;
            const inputHasChanged = JSON.stringify(previousInput) !== JSON.stringify(input);

            if (!inputHasChanged) {
                return;
            }

            Vue.set(steps, state.currentStepIndex, { ...currentStep, input });

            if (currentStep.type === 'choice') {
                // A different choice leads to an entirely different set of steps. Reset all input beyond this point.
                // todo prettify the code below

                let resetStepIndexes = [];

                steps
                    .filter(step => step._persistant_index > currentStep._persistant_index)
                    .forEach(step => {
                        Vue.set(steps, step._persistant_index, {
                            ...steps[step._persistant_index],
                            input: null,
                        });
                        resetStepIndexes.push(step._persistant_index);
                    });

                if (resetStepIndexes.length > 0) {
                    state.stepIndexesThatHaveBeenReached = state.stepIndexesThatHaveBeenReached
                        .filter(index => !resetStepIndexes.includes(index));

                    state.endHasBeenReached = false;
                }
            }
        },
        updateStep(state, { stepIndex, newStep }) {
            Vue.set(state.configuration.steps, stepIndex, newStep);
        },
        setEndHasBeenReached(state, endHasBeenReached) {
            state.endHasBeenReached = endHasBeenReached;
        },
        setUserWantsToUploadFiles(state, userWantsToUploadFiles) {
            state.userWantsToUploadFiles = userWantsToUploadFiles;
        },
        setCalculation(state, calculation) {
            state.calculation = calculation;
        },
        setIntegratedMode(state, integratedMode) {
            state.integratedMode = integratedMode;
        },
        setDeGrootOrderingOptions(state, orderingOptions) {
            state.deGrootOrderingOptions = orderingOptions;
        },
        unsetInputForStepsOfType(state, type) {
            const indexes = [];

            const steps = state.configuration?.steps ?? [];
            for (let step of steps) {
                if (step.type === type) {
                    step.input = null;
                    indexes.push(step._persistant_index);
                }
            }

            if (indexes.length > 0) {
                state.endHasBeenReached = false;
                state.stepIndexesThatHaveBeenReached = state.stepIndexesThatHaveBeenReached
                    .filter(index => !indexes.includes(index));
            }
        },
        setLocale(state, locale) {
            state.locale = locale;
        },
        cacheFallbackTranslationsResponse(state, { requestJson, response }) {
            Vue.set(state.fallbackTranslationResponsesCache, requestJson, response);
        },
        setPriceFormat(state, priceFormat){
            state.priceFormat = priceFormat;
        }
    },
};
