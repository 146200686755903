<template>
    <div class="flex flex-row gap-x-4">
        <StepDeGrootDeliveryOption v-for="deliveryOption of deliveryOptions"
                                   class="flex-1"
                                   :key="deliveryOption.key"
                                   :delivery-option="deliveryOption"
                                   :price-excl-vat="getPriceForDeliveryOption(deliveryOption)"
                                   :selected="deliveryKeyInput === deliveryOption.key"
                                   @selected="(title) => select(deliveryOption, title)"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import StepDeGrootDeliveryOption from './StepDeGrootDeliveryOption';

export default {
    components: {
        StepDeGrootDeliveryOption,
    },
    props: {
        step: { type: Object, required: true },
    },
    computed: {
        ...mapState({
            orderingOptions: state => state.deGrootOrderingOptions,
        }),
        edition() {
            const editionStep = this.$store.getters.visibleSteps.find(step => step.type === 'degroot-edition');
            return editionStep.input;
        },
        deliveryOptions() {
            const deliveryOptions = this.orderingOptions.deliveryOptions[this.edition.method];

            // DeGroot's API shamelessly returns equal dates for different delivery options, i.e. "Standaard" and "Express" both having the same exact delivery date.
            // It makes no sense to offer a more expensive option (Express) when the date is the same, so we filter out the more expensive option.
            // This works because we assume our delivery options to be ordered slowest to fastest: Standaard, Express, NextDay.

            const uniqueDateOptions = [];
            for (let deliveryOption of deliveryOptions) {
                const previousOption = uniqueDateOptions.length > 0 ? uniqueDateOptions[uniqueDateOptions.length - 1] : null;
                if (previousOption?.delivery_date === deliveryOption.delivery_date) {
                    continue;
                }
                uniqueDateOptions.push(deliveryOption);
            }

            return uniqueDateOptions;
        },
        prices() {
            return this.orderingOptions.editionOptions
                .find(editionOption => editionOption.method === this.edition.method && editionOption.quantity === this.edition.quantity)
                .prices;
        },
        deliveryKeyInput() {
            return this.step.input?.key;
        },
    },
    methods: {
        getPriceForDeliveryOption(deliveryOption) {
            return this.prices[deliveryOption.key] - this.prices['Standaard'];
        },
        select(deliveryOption, title) {
            this.$emit('submit', {
                key: deliveryOption.key,
                summary: `${title}: ${deliveryOption.delivery_date}`,
            });
        },
    },
};
</script>
