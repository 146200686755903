const appendFlat = (targetArray, arrayToAppend) => {
    return [
        ...targetArray,
        ...(arrayToAppend ?? []),
    ];
}

const intersect = (...arrays) => {
    if (arrays.length === 0) {
        return;
    }

    let result = arrays[0];
    for (let array of arrays.slice(1)) {
        result = result.filter(value => array.includes(value));
    }

    return result;
}

export { appendFlat, intersect };
