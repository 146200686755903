const formatCurrencyMixin = {
    methods: {
        formatCurrency(value, priceFormat) {

            const currencySign = this.decodeHtmlEntities(priceFormat.currency);
            const decimalSeparator = priceFormat.decimal_separator;
            const position = priceFormat.position;
            const thousandSeparator = priceFormat.thousand_separator;

            let [whole, decimal] = parseFloat(value).toFixed(2).split('.');

            whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

            // Construct the formatted price based on the given format
            let formatted;
            switch (position) {
                case 'left':
                    formatted = `${currencySign}${whole}${decimalSeparator}${decimal}`;
                    break;
                case 'right':
                    formatted = `${whole}${decimalSeparator}${decimal}${currencySign}`;
                    break;
                case 'right_space':
                    formatted = `${whole}${decimalSeparator}${decimal} ${currencySign}`;
                    break;
                case 'left_space':
                    formatted = `${currencySign} ${whole}${decimalSeparator}${decimal}`;
                    break;
                default:
                    formatted = `${whole}${decimalSeparator}${decimal}`;
                    break;
            }

            return formatted;
        },
        decodeHtmlEntities(str) {
            const doc = new DOMParser().parseFromString(str, "text/html");
            return doc.documentElement.textContent;
        }

    },
};


const formatIntegerMixin = {
    methods: {
        formatInteger(value) {
            return parseInt(value, 10).toLocaleString('nl-NL');
        },
    }
};

export {formatCurrencyMixin, formatIntegerMixin};
